import * as React from "react";
import type { HeadFC } from "gatsby";
import Header_Layout from "../components/layouts/Header_Layout";
import { Grid, Typography } from "@mui/material";
import G_Link from "../components/global/G_Link";

const SellPage = () => {
  return (
    <Header_Layout>
      <Grid container xs={12} p={6}>
        <Grid item xs={12} m={4}>
          <Typography variant="h3" mb={2}>
            Affiliate Links & Recommendations
          </Typography>
          <Typography variant="body1">
            We provide our userbase with recommendations of parts to buy for
            their PC builds, which often include affiliate links. We may earn a
            small commission when users purchase products through our links.
            This doesn’t affect which products are recommended. Our
            recommendation algorithms do not depend on affiliate link sales or
            commissions.
          </Typography>
        </Grid>
        <Grid item xs={12} m={4}>
          <Typography variant="h3" mb={2}>
            Amazon Associates & Other Programs
          </Typography>
          <Typography variant="body1">
            As an Amazon Associate, The Bit Lot may earn commissions from
            qualifying purchases from Amazon.com. Feel free to get{" "}
            <G_Link href="/contact">in touch</G_Link> if you have questions or
            comments about our affiliate links or relationships.
          </Typography>
        </Grid>
      </Grid>
    </Header_Layout>
  );
};

export default SellPage;

export const Head: HeadFC = () => <title>Disclaimer - BitLot.app</title>;
